import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import {
  AuthorizationErrorResponse,
  SocialLoginDoneResponse,
} from 'types/SocialLogin';
import { SocialLoginError } from 'constants/socialLogin';
import Logger from 'utils/logger';
import { openPopup } from 'utils/url';
import SocialLoginApi from 'app/modules/socialLogin/api';
import { useQuery } from 'react-query';
import { getDefaultScopesByPageType } from 'app/modules/socialLogin/utils';
import { queryKey } from 'constants/queryKey';
import TimeUtils from 'app/utils/time';

type SocialLoginContextValue = {
  status: ModuleStatus;
  authorization: SocialLoginDoneResponse;
  error: AuthorizationErrorResponse | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  authorize: (options: {
    pageId?: number;
    pageType: number;
    scopes?: string[];
  }) => void;
  reset: () => void;
};

const SocialLoginContext = createContext<SocialLoginContextValue | undefined>(
  undefined,
);
const SocialLoginContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [userKey, setUserKey] = useState<string>();
  const [status, setStatus] = useState<ModuleStatus>(MODULE_STATUS.Idle);
  const [authorization, setAuthorization] = useState<any>();
  const [error, setError] = useState<AuthorizationErrorResponse | undefined>();
  const popup = useRef<Window | null>(null);
  useQuery(
    queryKey.socialLoginPages(userKey),
    () => SocialLoginApi.fetchPages(userKey),
    {
      refetchInterval: (data) => {
        return TimeUtils.calcMilliseconds.seconds(1);
      },
      refetchIntervalInBackground: true,
      enabled: !!userKey && status === MODULE_STATUS.Loading,
      onSuccess: (data) => {
        Logger.log('Recieved social login data', data);

        if (data) {
          if (data.status === 'done') {
            setStatus(MODULE_STATUS.Succeeded);
            setAuthorization({
              data: data.pages,
              authorizationOptions: authorizationOptions.current,
            });
          }
        }
      },
      onError: () => {
        const errorResponse = {
          authorizationOptions: authorizationOptions.current,
        };
        Logger.error(errorResponse);
        setError(errorResponse);
        setStatus(MODULE_STATUS.Failed);
      },
    },
  );
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const authorizationOptions = useRef<{
    pageType: number | null;
    scopes: string[];
  }>({
    pageType: null,
    scopes: [],
  });

  useEffect(() => {
    const popupClosedListener = () => {
      const isPopupClosed = popup.current?.closed || popup.current === null;

      if (isPopupClosed) {
        Logger.log('Popup closed', status);
      }
    };

    if (status === MODULE_STATUS.Loading) {
      interval.current = setInterval(popupClosedListener, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [status]);

  useEffect(() => {
    return () => {
      popup.current?.close();
    };
  }, [popup]);

  async function authorize(options: {
    pageType: number;
    pageId?: number;
    scopes?: string[];
  }) {
    try {
      const scopes = options.scopes
        ? options.scopes
        : getDefaultScopesByPageType(options.pageType);

      authorizationOptions.current.pageType = options?.pageType;
      authorizationOptions.current.scopes = scopes;

      if (!authorizationOptions.current.pageType) {
        throw new Error('Page type not defined');
      }

      setUserKey(undefined);
      setAuthorization(undefined);
      setError(undefined);
      setStatus(MODULE_STATUS.Loading);

      const socialLoginUrlResponse = await SocialLoginApi.requestLoginUrl(
        authorizationOptions.current.pageType,
        {
          scopes: authorizationOptions.current?.scopes,
        },
      );

      if (socialLoginUrlResponse.userKey) {
        setUserKey(socialLoginUrlResponse.userKey);
      }

      popup.current = openPopup(socialLoginUrlResponse.loginUrl);
    } catch (e) {
      const error = {
        reason:
          (e instanceof Error && e.message) ||
          SocialLoginError.unableToAuthorize,
        authorizationOptions: authorizationOptions.current,
      };

      Logger.error(error);
      setError(error);
      setStatus(MODULE_STATUS.Failed);
    }
  }

  const reset = () => {
    setStatus(MODULE_STATUS.Idle);
    setUserKey(undefined);
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  return (
    <SocialLoginContext.Provider
      value={{
        status,
        authorization,
        error,
        isSuccess: status === MODULE_STATUS.Succeeded,
        isLoading: status === MODULE_STATUS.Loading,
        isError: status === MODULE_STATUS.Failed,
        authorize,
        reset,
      }}
    >
      {children}
    </SocialLoginContext.Provider>
  );
};

export default SocialLoginContextProvider;

export const useSocialLoginContext = () => {
  const context = useContext(SocialLoginContext);

  if (!context) {
    throw new Error(
      'useSocialLoginContext must be used within a SocialLoginContextProvider',
    );
  }

  return context;
};
